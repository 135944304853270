<script>
import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import * as moment from "moment";
import Stat from "../dashboard/widget";
// import TransactionVolume from "../dashboard/transaction_volume";
// import TransactionByPG from "../dashboard/donut-transaction-by-pg";
// import TransactionByStatus from "../dashboard/donut-transaction-by-status"; 
// import TransactionByType from "../dashboard/donut-transaction-by-type";
// import TransactionSeasonality from "../dashboard/transaction_seasonality";
// import TransactionVolumeStackedbar from "../dashboard/transaction_volume_stackedbar.vue";
import Multiselect from 'vue-multiselect';
import Chart from "chart.js/dist/chart.min.js";
// import Chart from 'chart.js/auto/auto.js';
// var Chart = require('chart.js/auto');
import 'chartjs-adapter-luxon';
import * as crypto from "crypto";
import Filters from '../../../components/filters.vue';
import Loader from "@/components/loader";
import {
  FETCH_LATEST_TXNS,
  FETCH_TRANSACTION_STATUS,
  FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS_METRICS,
  FETCH_TRANSACTION_STATISTICS,
  FETCH_TRANSACTION_STATS,
  // FETCH_TRANSACTION_VOLUME,
  // FETCH_TRANSACTION_VOLUME_SEASONALITY,
  // FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS,
  // FETCH_TRANSACTION_VOLUME_STACKED_BAR,
  FETCH_MERCHANTS_LIST,
  UPDATE_SUB_USER_DETAILS,
  FETCH_MERCHANT_ENTITY_DETAILS,
  UPDATE_MERCHANT_ENTITY_DETAILS
} from '../../../state/actions.type';
var Barcurrency = "";
let directTimeBracket = "";

/**
 * Dashboard component
 */
export default { 
  components: {
    Layout,
    // PageHeader,
    Stat,
    // TransactionVolume,
    // TransactionByPG,
    // TransactionByStatus,
    // TransactionByType,
    // TransactionSeasonality,
    // TransactionVolumeStackedbar,
    Multiselect,
    Filters,
    Loader
  },

  mounted() {
    this.$OneSignal.on('subscriptionChange', (isSubscribed) => {
      console.log("The user's subscription state is now:", isSubscribed);
      if(isSubscribed) {
        this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.currentUser.subUserUuid, subUserDetails: {is_push_notifications_enabled: true}})
      }
    });
    this.statisticsChart();
    this.paymentChannelChart();
    this.paymentMethodChart();
  },

  created() {

    const externalUserId = this.currentUser.subUserUuid; // MERCHANT_SUBUSER_UUID
    if((this.currentUser.roles.includes('admin') || this.currentUser.roles.includes('sub-admin')) && !this.isMasterMerchant && externalUserId) {
      
      this.$OneSignal.isPushNotificationsEnabled().then((enabled) => {
        console.log("isPushNotificationsEnabled success: ", enabled);
        if(enabled) {
          if(!this.currentUser.is_push_notifications_enabled) {
            this.$store.dispatch(`accounts/${UPDATE_SUB_USER_DETAILS}`, {subUserID: this.currentUser.subUserUuid, subUserDetails: {is_push_notifications_enabled: true}})
          }
        } else {
          this.$OneSignal.showSlidedownPrompt();

          const hmac = crypto.createHmac('sha256', process.env.VUE_APP_ONESIGNAL_REST_API_KEY);
          hmac.update(externalUserId);
          let secure_hash = hmac.digest('hex')
          console.log('secure_hash', secure_hash);
          console.log('externalUserId', externalUserId);
          this.$OneSignal.setExternalUserId(externalUserId, secure_hash);
        }

      });
    }
    this.currentSelectedFilter = this.merchantDetails.merchant_dashboard_currency ? this.merchantDetails.merchant_dashboard_currency : this.convertCountryToCurrency
    Barcurrency = this.merchantDetails.merchant_dashboard_currency ? this.merchantDetails.merchant_dashboard_currency : this.convertCountryToCurrency;
    this.$store.dispatch(`merchant/${FETCH_MERCHANT_ENTITY_DETAILS}`)
    .then(() => {
      this.currentSelectedFilter = this.merchantDetails.merchant_dashboard_currency ? this.merchantDetails.merchant_dashboard_currency : this.convertCountryToCurrency
      Barcurrency = this.merchantDetails.merchant_dashboard_currency ? this.merchantDetails.merchant_dashboard_currency : this.convertCountryToCurrency;
      if(this.isMasterMerchant){
        this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
          pageIndex: 1,
          pagesize: 10,
          filters: { source: "default" }
        }).then(() => {
          this.updateFilter()
          this.isMasterMerchantDataFatched = true;
        });
      }else{
          this.updateFilter()
          this.withLastCurrency = true;
      }
    })
  },
  filters: {
    paymentStatus(row) {
      let status;
      switch (row.item.refund_status) {
        case 'Initiated':
          status = 'Refund Created';
          break;
        case 'Failed':
          status = 'Refund Failed';
          break;
        case 'PartialProcessed':
          status = 'Partial Refund Successful';
          break;
        case 'Processed':
          status = 'Refund Successful';
          break;
        default:
          status = row.value;
          break;
      }
      return status;
    },
    date(value) {
      return moment(value).format("DD MMM YYYY");
    },
    time(value) {
        return 'at ' + moment(value).format("hh:mm A");
    },
  },

  data() {
    return {
    options: {
      layout:{
        padding:0
      },
      plugins : {
        legend: {
          display: true,
          position: 'bottom',
        },
        tooltip: {
          enabled: false,
          external: function(context) {
              // Tooltip Element
              var tooltipEl = document.getElementById('Bar-chart-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'Bar-chart-tooltip';
                  tooltipEl.innerHTML = '<table></table>';
                  document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              var tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                  tooltipEl.classList.add('no-transform');
              }
              if (tooltipModel.dataPoints) {
                  var monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
                  var titleLines = tooltipModel.dataPoints[0].raw; 
                  var date = new Date(tooltipModel.dataPoints[0].label);
                  var bodyLines =  `${monthAbbr[moment(date).month()]} ${moment(date).year()}`;
                  var innerHtml = '<thead>';
                  if(tooltipModel.dataPoints[0].dataset.type === 'line'){
                    innerHtml += '<tr><th style="font-size: 13px;font-weight: 350;">' + titleLines + '%' + '</th></tr>';
                  }else{
                    innerHtml += '<tr><th style="font-size: 13px;font-weight: 350;">' + titleLines.toLocaleString('en-US', {style : 'currency', currency : Barcurrency === 'ALL' ? 'USD' : Barcurrency}) + '</th></tr>';
                  }
                  
                  innerHtml += '</thead><tbody>';
                  innerHtml += '<tr><td style="font-size: 10px;padding-top: 4px;text-align: center;">'  + bodyLines + '</td></tr>';
                  innerHtml += '</tbody>';

                  var tableRoot = tooltipEl.querySelector('table');
                  tableRoot.innerHTML = innerHtml;
              }
              var position = context.chart.canvas.getBoundingClientRect();
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth/2 + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 5  + 'px';
              tooltipEl.style.padding = "4px 8px 8px 8px"
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.backgroundColor = '#000';
              tooltipEl.style.color = '#fff';
              tooltipEl.style.borderRadius = "4px"
          }
        }
      },
      datasets: {
        bar: { 
          categoryPercentage: 0.4,
          barPercentage: .9,
        },
        line:{
          tension: 0.4
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
            grid: {
              display: true,
              drawOnChartArea: false,
            },
            ticks: {
              autoSkip: true,
              maxTicksLimit: 12,
              maxRotation: 20,
              minRotation: 20,
              // stepSize: 4
              callback: function(index) {
                const monthAbbr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']; 
                const date = new Date(this.chart.data.labels[index]);
                if(directTimeBracket == "YEAR"){
                  return moment(date).format('YYYY');
                }else if(directTimeBracket == "HOUR"){
                  return moment(date).format('HH:mm')
                }else if(directTimeBracket == "MONTH"){
                  return `${monthAbbr[moment(date).month()]} ${moment(date).year()}`;
                }else{
                  return `${moment(date).date()} ${monthAbbr[moment(date).month()]}`;
                }
              },
            }
          },
          bar: {
            type: 'linear',
            display: true,
            position: 'left',
            ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value) {
                      return (value).toLocaleString('en-US', {style : 'currency', currency : Barcurrency === 'ALL' ? 'USD' : Barcurrency});
                  }
              },
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              }
          },
          line: {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: {
              // Include a dollar sign in the ticks
              callback: function(value) {
                  return value + '%'  ;
              }
            }
          },
        },
    },
    options2: {
      layout:{
        padding:0
      },
      plugins : {
        legend: {
          display: true,
          position: 'right',
        },
        tooltip: {
          enabled: false,
          // callbacks: {
          //   label: function(tooltipItem, data) {
          //     if(data && data.labels.includes("NO DATA")) {
          //       return "NO DATA";
          //     }
          //     let sum = 0;
          //     let dataArr = data.datasets[0].data;
          //     dataArr.map(data => {
          //         sum += data;
          //     });
          //     let percentage = ((data['datasets'][0]['data'][tooltipItem['index']])*100 / sum).toFixed(2)
          //     return `${data['labels'][tooltipItem['index']]}: ${percentage}%, `
          //      + `Amount: ${(data['datasets'][0]['data'][tooltipItem['index']]).toLocaleString('en-US', {style : 'currency', currency : Barcurrency === 'ALL' ? 'USD' : Barcurrency})}, `
          //      + `Count: ${data['datasets'][0]['count_data'][tooltipItem['index']]}`;
          //   }
          // }
            external: function(context) {
              // Tooltip Element
              var tooltipEl = document.getElementById('Bar-chart-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                  tooltipEl = document.createElement('div');
                  tooltipEl.id = 'Bar-chart-tooltip';
                  tooltipEl.innerHTML = '<table></table>';
                  document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              var tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                  tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                  tooltipEl.classList.add('no-transform');
              }
              if (tooltipModel.dataPoints) {
                  var titleLines = tooltipModel.dataPoints[0].raw; 
                  var bodyLines =  tooltipModel.dataPoints[0].label;

                  // let sum = 0;
                  // let dataArr = tooltipModel.dataPoints[0].dataset.data;
                  // dataArr.map(data => {
                  //   sum += data;
                  // });
                  // let percentage = (titleLines * 100 / sum).toFixed(2)

                  // console.log(percentage)

                  var innerHtml = '<thead>';
                  innerHtml += '<tr><th style="font-size: 13px;font-weight: 350;">' + titleLines.toLocaleString('en-US', {style : 'currency', currency : Barcurrency === 'ALL' ? 'USD' : Barcurrency}) + '</th></tr>';
                  
                  innerHtml += '</thead><tbody>';
                  innerHtml += '<tr><td style="font-size: 10px;padding-top: 4px;text-align: center;">'  + bodyLines + '</td></tr>';
                  innerHtml += '</tbody>';

                  var tableRoot = tooltipEl.querySelector('table');
                  tableRoot.innerHTML = innerHtml;
              }
              var position = context.chart.canvas.getBoundingClientRect();
              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.offsetWidth/2 + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.offsetHeight - 5  + 'px';
              tooltipEl.style.padding = "4px 8px 8px 8px"
              tooltipEl.style.pointerEvents = 'none';
              tooltipEl.style.backgroundColor = '#000';
              tooltipEl.style.color = '#fff';
              tooltipEl.style.borderRadius = "4px"
          }
        },
      },
      animation: {
        animateScale: true,
        animateRotate: true
      },
      datasets: {
        doughnut: { 
          spacing:5,
          borderRadius:3,
          borderWidth:0,
          weight:.9,
          cutout:80
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    },
      // amountAndCountry:"Amount",
      merchant_uuids:[],
      filterOptions: ['ALL', 'VND', 'THB', 'SGD', 'IDR', 'PHP', 'HKD', 'KHR','WST', 'USD', 'INR', 'KRW', 'KES', 'ZAR', 'NGN', 'JPY'],
      currentSelectedFilter: "",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: "date",
      sortDesc: true,
      fields: [
        { key: "date", sortable: false, label: 'views.payments.table.fields.date', tooltip: "views.payments.table.tooltip.date" },
        { key: "billing_name", sortable: false, label: "views.payment_links.table.fields.customer_name", tooltip: "views.payment_links.table.tooltip.customer_name" },
        { key: "txn_ref", sortable: false, label: "Transaction Reference", tooltip: "" },
        { key: "channel_name", sortable: false, label: "views.payment_links.table.fields.channel_name", tooltip: "views.payments.table.tooltip.psp" },
        { key: "method_name", sortable: false, label: "views.payments.table.fields.payment_method", tooltip: "views.payments.table.tooltip.payment_method" },
        { key: "status", sortable: false, label: "views.payments.table.fields.payment_status", tooltip: "views.payments.table.tooltip.payment_status" },
        { key: "amount", sortable: false, label: "views.payments.table.fields.amount", tooltip: "views.payments.table.tooltip.amount" }
      ],
      fromDate: "",
      toDate: "",
      statisticsChartStore:"",
      paymentMethodChartStore:"",
      paymentChannelChartStore:"",
      time_bracket_array:[],
      selected_time_bracket:'',
      isMasterMerchantDataFatched: false,
      withLastCurrency: false
    }
  },

  computed: {
    statusData() {
      return this.$store.state.dashboard.txn_status;
    },
    txnStatisticsData() {
      return this.$store.state.dashboard.txn_vol_statistics_bar;
    },
    txnVolByPmtChannel() {
      return this.$store.state.dashboard.txn_vol_by_pmt_channel_new;
    },
    txnVolByPmtMethod() {
      return this.$store.state.dashboard.txn_vol_by_pmt_method_new;
    },
    paymentsDataFromStore() {
      return this.$store.state.dashboard.lates_txn;
    },
    merchantDetails() {
        return this.$store.state.merchant.entityDetails;
    },
    // title() {
    //   return this.$t('views.dashboard.dashboard')
    // },

    // items() {
    //   return  [
    //     {
    //       text: "PortOne",
    //       active: true
    //     },
    //     {
    //       text: this.$t('views.dashboard.dashboard'),
    //       active: true
    //     }
    //   ]
    // },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },

    isFetchingStackedBarData() {
      return this.$store.state.dashboard.is_fetching_stacked_bar_data;
    },

    isFetchingStatusData() {
      return this.$store.state.dashboard.is_fetching_status_data;
    },

    isFetchingPieChartData() {
      return this.$store.state.dashboard.is_fetching_pieChart_data;
    },

    isNavIsFatching() {
      return this.$store.getters["dashboard/isNavIsFatching"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    userPermissions() {
      return this.$store.getters["auth/getUserPermissionsList"];
    },

    convertCountryToCurrency() {
      return this.$store.getters["auth/getConvertCountry"];
    },
    currentUser() {
      return this.$store.state.auth.currentUser;
    }
  },
  watch: {
    // merchant_uuids() {
    //   var filter = this.appliedFilter;
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_STATS}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_SEASONALITY}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_STACKED_BAR}`, { filter: filter, isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    //   this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { filter: filter, aggtype: 'txnvolume', isMasterMerchant:this.isMasterMerchant, merchant_uuids:this.merchant_uuids, currency:this.currentSelectedFilter});
    // },
    // currentSelectedFilter() {
    //   Barcurrency = this.currentSelectedFilter;
    //   this.updateFilter(this.appliedFilter);
    // },
    txnStatisticsData(){
      //  this.statisticsChart();
      this.statisticsChartStore.data = this.txnStatisticsData
      this.statisticsChartStore.update();
    },
    txnVolByPmtMethod(){
      // this.paymentMethodChart();
      this.paymentMethodChartStore.data = this.txnVolByPmtMethod
      this.paymentMethodChartStore.update();
    },
    txnVolByPmtChannel(){
      // this.paymentChannelChart();
      this.paymentChannelChartStore.data = this.txnVolByPmtChannel
      this.paymentChannelChartStore.update();
    },
    
  },

  methods: {
    async updateFilter() {
      let merchant_uuids = this.merchant_uuids;
      if(this.isMasterMerchant && merchant_uuids.length === 0) {
        merchant_uuids = this.merchantListDataFromStore;
      }
      let from_date = ""
      let to_date = ""
      if(this.fromDate) {
        from_date = new Date(moment(this.fromDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')); 
        from_date =from_date.toISOString()
      } else {
        from_date = new Date(moment('2018-01-01T00:00:00.000').format('YYYY-MM-DD HH:mm:ss'))
        from_date =from_date.toISOString()
      }

      if(this.toDate) {
        to_date = new Date(moment(this.toDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'))
        to_date =to_date.toISOString()
      } else {
        to_date = new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
        to_date =to_date.toISOString()
      }
      this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_STATS}`, {  isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter, from_date:from_date, to_date:to_date});
      // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME}`, { isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter});
      // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_SEASONALITY}`, { isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter});
      // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_VOLUME_STACKED_BAR}`, { isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter});
      // this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS}`, { aggtype: 'txnvolume', isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter});
      this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_STATUS}`, { isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter, from_date:from_date, to_date:to_date});
      this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_DATA_FOR_DOUGHNUT_CHARTS_METRICS}`, { isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter, from_date:from_date, to_date:to_date});
      this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_STATISTICS}`, { isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter, from_date:from_date, to_date:to_date, time_bracket:this.selected_time_bracket});
      this.$store.dispatch(`dashboard/${FETCH_LATEST_TXNS}`, {isMasterMerchant:this.isMasterMerchant, merchant_uuids:merchant_uuids})
    },
    time_bracket(val){
      this.selected_time_bracket = val
      directTimeBracket = val;
      let merchant_uuids = this.merchant_uuids;
      if(this.isMasterMerchant && merchant_uuids.length === 0) {
        merchant_uuids = this.merchantListDataFromStore;
      }
      let from_date = ""
      let to_date = ""
      if(this.fromDate) {
        from_date = new Date(moment(this.fromDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')); 
        from_date =from_date.toISOString()
      } else {
        from_date = new Date(moment('2018-01-01T00:00:00.000').format('YYYY-MM-DD HH:mm:ss'))
        from_date =from_date.toISOString()
      }

      if(this.toDate) {
        to_date = new Date(moment(this.toDate, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'))
        to_date =to_date.toISOString()
      } else {
        to_date = new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
        to_date =to_date.toISOString()
      }
      this.$store.dispatch(`dashboard/${FETCH_TRANSACTION_STATISTICS}`, { isMasterMerchant:this.isMasterMerchant, merchant_uuids: merchant_uuids, currency:this.currentSelectedFilter, from_date:from_date, to_date:to_date, time_bracket:this.selected_time_bracket});
    },
    selectedFilterChanged() {
      Barcurrency = this.currentSelectedFilter;
      var formData = {
        merchant_dashboard_currency: this.currentSelectedFilter,
      }
      this.$store.dispatch(`merchant/${UPDATE_MERCHANT_ENTITY_DETAILS}`, formData)
      .then(() => {
            this.updateFilter()
      })
      .catch((error) => {
        // Added new user
        console.log(error); 
        this.updateFilter()
      });
    },
    merchantUuidChanged() {
      this.updateFilter()
    },
    statisticsChart() {
      const ctx = this.$refs.statisticsBar.getContext('2d');
      this.statisticsChartStore =  new Chart(ctx, {
        type: 'bar',
        options:this.options,
        customCanvasBackgroundColor:"#f2f2f2",
        data: this.txnStatisticsData
      });
    },
    paymentMethodChart() {
      const ctx = this.$refs.paymentMethoddoughnut.getContext('2d');
      this.paymentMethodChartStore =  new Chart(ctx, {
        type: 'doughnut',
        elements: {
          center: {
            text: "vivek"
          }
        },
        options:this.options2,
        customCanvasBackgroundColor:"#f2f2f2",
        data: this.txnVolByPmtMethod
      });
    },
    paymentChannelChart() {
      const ctx = this.$refs.paymentChanneldoughnut.getContext('2d');
      this.paymentChannelChartStore =  new Chart(ctx, {
        type: 'doughnut',
        elements: {
          center: {
            text: "vivek"
          }
        },
        options:this.options2,
        customCanvasBackgroundColor:"#f2f2f2",
        data: this.txnVolByPmtChannel
      });
    },
      dateChanged(value) {
        this.fromDate = value[0];
        this.toDate = value[1];
        this.time_bracket_array = []
        var years = moment(this.toDate, 'DD-MM-YYYY HH:mm').diff(moment(this.fromDate, 'DD-MM-YYYY HH:mm'), 'years',true); 
        var months = Math.round(years * 12)
        var weeks =  Math.round( years * 52 )
        var days = Math.round( years * 365 )
        var hours = Math.round( days * 24 )

        if(years > 1 && years <= 12){
          this.time_bracket_array.push("YEAR")
        }
        // if(months/6 >= 1 && months/6 <= 12){
        //   this.time_bracket_array.push("6M")
        // }
        if(months >= 1 && months <= 12){
          this.time_bracket_array.push("MONTH")
        }
        if(weeks >= 1 && weeks <= 12) {
          this.time_bracket_array.push("WEEK")
        }
        if(days >= 1 && days <= 12) {
          this.time_bracket_array.push("DAY")
        }
        // if(hours/6 >= 1 && hours/6 <= 12) {
        //   this.time_bracket_array.push("6H")
        // } 
        if(hours >= 1 && hours <= 12) {
          this.time_bracket_array.push("HOUR")
        }
        
        this.selected_time_bracket = this.time_bracket_array[0];   
        directTimeBracket = this.time_bracket_array[0];  
        if(this.isMasterMerchantDataFatched || !this.isMasterMerchant && (this.withLastCurrency)){
          this.updateFilter();
        } 
    },
  },
  // mounted() {
    // this.renderChart();
    // this.renderChart2();
    //  this.renderChart3();
  // },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row"> 
      <div class="col-xl-12">
        <div style="position: relative;">
          <filters class="mb-3" @date-change="dateChanged" selectedDay="Last week"/>
          <div style="position: absolute;right: 10px;top: 10px;">
            <!-- <div style="width: 130px; float: right;">
              <multiselect
                v-model="amountAndCountry"
                placeholder="Select Type"
                :searchable="false"
                :options="['Amount','Count']"
                :multiple="false"
                class="sm-multiselect custom-multiselect cursor-pointer"
                :taggable="true"
                :show-labels="false"
              ></multiselect>
            </div> -->
            <div style="width: 118px; float: right;">
              <multiselect
                v-model="currentSelectedFilter"
                placeholder="Select Currency"
                :searchable="false"
                :options="filterOptions"
                :multiple="false"
                 @input="selectedFilterChanged"
                class="sm-multiselect custom-multiselect cursor-pointer"
                :taggable="true"
                :show-labels="false"
              ></multiselect>
            </div>
            <div style="float: right;">
                <div v-if="isMasterMerchant" class="d-md-inline-block float-left mr-3" style="width: 300px;">
                  <multiselect
                    v-model="merchant_uuids"
                    :options="merchantListDataFromStore"
                    :placeholder="$t('views.dashboard.search_merchants')"
                    track-by="email_id"
                    label="email_id"
                    size="sm"
                    @input="merchantUuidChanged"
                    :searchable="true"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
              </div>
              <!-- <div v-if="userPermissions.includes('create-payment-links') && !isMasterMerchant" class="d-none d-md-inline-block">
                <router-link
                  class="btn btn-primary mb-2 btn-sm"
                  to="/payment-links?new=payment-link"
                >
                  {{ $t("views.payment_links.create_pay_link") }}
                </router-link>
              </div> -->
              <!-- <div class="float-right ml-3 d-md-inline-block">
                <b-form-select v-model="currentSelectedFilter" @change="selectedFilterChanged" :disabled ="isNavIsFatching ? true : false" style="display: inline-block;color: #252B3A;border-color: #252B3A;" size="sm" :options="filterOptions"></b-form-select>&nbsp;
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-12">
            <Stat />
          </div>
        </div>
      </div>
      <div class="col-xl-12">
            <div class="row">
                <div class="col-md-4">
                    <div class="card" style="background-color: #000; color:#fff;">
                      <div class="card-body" style="min-height: 405px;">
                          <div class="trans-status-heading" style="font-size: 16px;color: #fff;margin-bottom: 25px;">Transaction Status</div>
                          <div class="trans-status-section" style="margin-bottom: 25px;">
                              <div class="trans-status-img d-inline-block align-middle">
                                  <img style="width: 50px;height: 50px; margin-right: 25px;" src="@/assets/images/initeated_transaction.png" alt="" />
                              </div>
                              <div class="trans-status-content d-inline-block align-middle" style="width: calc(100% - 75px);">
                                <div id="trans-status-val">
                                  <div v-if="statusData.Initiated && !isFetchingStatusData" class="trans-status-amount text-truncate" style="font-size: 25px; margin-bottom: 15px;">
                                    {{ (statusData.Initiated.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter}) }}
                                  </div>
                                  <div style="background-color: transparent; height: 25px;" v-else class="skeleton-box w-75"></div>
                                  <b-tooltip v-if="statusData.Initiated && ((statusData.Initiated.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})).length > 15" target="trans-status-val" triggers="hover">
                                      {{ (statusData.Initiated.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter}) }}
                                  </b-tooltip>
                                </div>
                                <div class="trans-status-text text-truncate" style="color: #dcdad5b3; text-transform: uppercase;font-size: 14px;margin-bottom: 5px;">Initiated  transactions</div>
                              </div>
                          </div>
                          <div class="trans-status-section" style="margin-bottom: 25px;">
                              <div class="trans-status-img d-inline-block align-middle">
                                  <img style="width: 50px;height: 50px; margin-right: 25px;" src="@/assets/images/success_transaction.png" alt="" />
                              </div>
                              <div class="trans-status-content d-inline-block align-middle" style="width: calc(100% - 75px);">
                                <div id="success-txn-val">
                                  <div  v-if="statusData.Success && !isFetchingStatusData" class="trans-status-amount text-truncate" style="font-size: 25px; margin-bottom: 15px;">
                                    {{(statusData.Success.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})}}
                                  </div>
                                  <div style="background-color: transparent; height: 25px;" v-else class="skeleton-box w-75"></div>
                                  <b-tooltip v-if="statusData.Success && ((statusData.Success.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})).length > 15" target="success-txn-val" triggers="hover">
                                      {{(statusData.Success.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})}}
                                  </b-tooltip>
                                </div>
                                <div class="trans-status-text text-truncate" style="color: #dcdad5b3;text-transform: uppercase; font-size: 14px;margin-bottom: 5px;">SUCCESSFUL TRANSACTIONS</div>
                              </div>
                          </div>
                          <div class="trans-status-section" style="margin-bottom: 25px;">
                              <div class="trans-status-img d-inline-block align-middle">
                                  <img style="width: 50px;height: 50px; margin-right: 25px;" src="@/assets/images/expired_transaction.png" alt="" />
                              </div>
                              <div class="trans-status-content d-inline-block align-middle" style="width: calc(100% - 75px);">
                                <div id="expired-txn-val">
                                  <div v-if="statusData.Expired && !isFetchingStatusData"  class="trans-status-amount text-truncate" style="font-size: 25px; margin-bottom: 15px;">
                                    {{(statusData.Expired.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})}}
                                  </div>
                                  <div style="background-color: transparent; height: 25px;" v-else class="skeleton-box w-75"></div>
                                  <b-tooltip v-if="statusData.Expired && ((statusData.Expired.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})).length > 15" target="expired-txn-val" triggers="hover">
                                        {{(statusData.Expired.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})}}
                                  </b-tooltip>
                                </div>
                                <div class="trans-status-text text-truncate" style="color: #dcdad5b3;text-transform: uppercase; font-size: 14px;margin-bottom: 5px;">Expired transactions</div>
                              </div>
                          </div>
                          <div class="trans-status-section">
                              <div class="trans-status-img d-inline-block align-middle">
                                  <img style="width: 50px;height: 50px; margin-right: 25px;" src="@/assets/images/failed_transaction.png" alt="" />
                              </div>
                              <div class="trans-status-content d-inline-block align-middle" style="width: calc(100% - 75px);">
                                <div id="failed-txn-val">
                                  <div v-if="statusData.Failed && !isFetchingStatusData"  class="trans-status-amount text-truncate" style="font-size: 25px; margin-bottom: 15px;">
                                    {{(statusData.Failed.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})}}
                                  </div>
                                  <div style="background-color: transparent;height: 25px;" v-else class="skeleton-box w-75"></div>
                                  <b-tooltip v-if="statusData.Failed && ((statusData.Failed.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})).length > 15" target="failed-txn-val" triggers="hover">
                                      {{(statusData.Failed.amount).toLocaleString('en-US', {style : 'currency', currency : currentSelectedFilter === 'ALL' ? 'USD' : currentSelectedFilter})}}
                                  </b-tooltip>  
                                </div>
                                <div class="trans-status-text text-truncate" style="color: #dcdad5b3;text-transform: uppercase; font-size: 14px;margin-bottom: 5px;">Failed Transactions</div>
                              </div>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="col-md-8">
                      <div class="card">
                         <Loader v-if="isFetchingStackedBarData" ></Loader>
                         <div class="static_section">
                          <div>Statistics</div>
                          <div style="display: flex; gap: 10px;">
                              <div :class="{'active': this.selected_time_bracket === 'HOUR'}" 
                               @click="time_bracket('HOUR')"
                               v-if='this.time_bracket_array.indexOf("HOUR") > -1' class="static_filters">1H</div>
                              <!-- <div class="static_filters">6H</div> -->
                              <div :class="{'active': this.selected_time_bracket === 'DAY'}" 
                              @click="time_bracket('DAY')"
                              v-if='this.time_bracket_array.indexOf("DAY") > -1' class="static_filters">1D</div>
                              <div :class="{'active': this.selected_time_bracket === 'WEEK'}" 
                              @click="time_bracket('WEEK')"
                              v-if='this.time_bracket_array.indexOf("WEEK") > -1' class="static_filters">1W</div>
                              <div :class="{'active': this.selected_time_bracket === 'MONTH'}" 
                              @click="time_bracket('MONTH')"
                              v-if='this.time_bracket_array.indexOf("MONTH") > -1' class="static_filters">1M</div>
                              <!-- <div class="static_filters">6M</div> -->
                              <div :class="{'active': this.selected_time_bracket === 'YEAR'}" 
                              @click="time_bracket('YEAR')"
                              v-if='this.time_bracket_array.indexOf("YEAR") > -1'  class="static_filters">1Y</div>
                          </div>
                        </div>
                        <div class="card-body">
                            <canvas  :style="{'height': `305px`, 'border-radius':'5px'}" ref="statisticsBar"></canvas>
                        </div>
                      </div>
                </div>
            </div>
        
      </div>
        <div class="col-xl-12">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                      <Loader v-if="isFetchingPieChartData" ></Loader>
                      <div style="padding: 25px 25px 0px 25px;">{{ $t('views.dashboard.trxn_vol_by_pm') }}</div>
                      <div class="card-body">
                          <canvas  :style="{'height': `230px`, 'border-radius':'5px'}" ref="paymentMethoddoughnut"></canvas>
                      </div>
                    </div>
                </div>
                <div class="col-md-6">
                      <div class="card">
                      <Loader v-if="isFetchingPieChartData" ></Loader>
                        <div style="padding: 25px 25px 0px 25px;">{{ $t('views.dashboard.trxn_vol_by_pc') }}</div>
                        <div class="card-body">
                             <canvas  :style="{'height': `230px`, 'border-radius':'5px'}" ref="paymentChanneldoughnut"></canvas>
                        </div>
                      </div>
                </div>
            </div>
        
      </div>

      <div class="col-xl-12">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                      <div class="card-body" style="padding-left: 0px;padding-right: 0px;">
                          <div style="display: flex;justify-content: space-between;">
                            <div style="padding-bottom: 20px;padding-left: 20px;color: black;font-weight: 500;font-size: 16px;">Recent Transactions</div>
                            <router-link :to="'/transactions/payments'">
                              <div class="text-chai" style="font-size: 10px; padding-right: 20px; cursor: pointer;">
                                View all 
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                  <g id="Iconsax/Linear/arrowright">
                                  <path id="Vector" d="M6.01266 2.4707L8.54183 4.99987L6.01266 7.52904M1.4585 4.99987H8.471" stroke="#FC6B2D" stroke-opacity="1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                  </g>
                                </svg>
                              </div>
                            </router-link>
                          </div>
                          <div class="table-responsive text-left table-custom" style="margin-bottom: 0px">
                            <b-table
                              class="table-centered"
                              :items="paymentsDataFromStore"
                              :fields="fields"
                              responsive="sm"
                              :per-page="perPage"
                              :bordered="true"
                              sticky-header="500px"
                              show-empty
                              no-local-sorting
                              :empty-text="$t('common.no-records')"
                              busy.sync="true"
                              hover
                            >
                              <template v-slot:head()="data">
                                <span v-b-tooltip.hover.top :title='$t(data.field.tooltip)'>
                                  {{ $t(data.label) }}
                                </span>
                              </template>

                              <template v-slot:cell(merchant_order_ref)="{ value }">
                                <div class="text-left">
                                  {{value}}
                                </div>
                              </template>

                               <template v-slot:cell(txn_ref)="{ value }">
                                <div class="text-chai">
                                  {{value}}
                                </div>
                              </template>

                               <template v-slot:cell(amount)="{ value, item }">
                                  {{ (value).toLocaleString('en-US', {style : 'currency', currency : item.currency === 'ALL' ? 'USD' : item.currency}) }}
                                </template>

                              <template #cell(date)="row">
                                  <p class="table-date">{{ row.value | date }}</p>
                                  <p style="padding-top: 3px;" class="table-time">{{ row.value | time }}</p>
                              </template>

                              <template v-slot:cell(status)="row">
                                <div
                                  class="badge custom-status"

                                  :class="{
                                    'badge-soft-danger': ['Error', 'Failed'].includes(row.value) || (row.item.refund_status && row.item.refund_status === 'Processed'),
                                    'badge-soft-success': row.value === 'Success',
                                    'badge-soft-warning': (row.item.refund_status && row.item.refund_status === 'Initiated') || row.value === 'Initiated',
                                    'badge-soft-secondary': ['Expired', 'Voided'].includes(row.value),
                                    'badge-orange': ['Pending'].includes(row.value),
                                    'badge-soft-info': ['Authorized', 'Processing', 'In_process'].includes(row.value),
                                    'badge-purple': row.value === 'Under Review',
                                    'badge-gold': (row.item.refund_status && row.item.refund_status === 'PartialProcessed'),
                                    'badge-crimson-red': (row.item.refund_status && row.item.refund_status === 'Failed')
                                  }"
                                >{{ row | paymentStatus }}</div>
                              </template>


                              <template v-slot:cell(channel_name)="row">
                                <div style="font-size: 13px;" class="">
                                  <span class="align-middle">{{ row.value }}</span>
                                </div>
                              </template>
                            </b-table>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        
      </div>
      <!-- <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-8">
            <div class="row">
              <div class="col-xl-12">
                <TransactionVolumeStackedbar :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" :isFetchingStackedBarData="isFetchingStackedBarData" />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <TransactionSeasonality :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" :isFetchingSeasonalityData="isFetchingSeasonalityData" />
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="row">
              <div class="col-xl-12">
                <TransactionByStatus :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" :isFetchingPieChartData="isFetchingPieChartData" />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <TransactionByPG :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" :isFetchingPieChartData="isFetchingPieChartData" />
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12">
                <TransactionByType :filter="appliedFilter" :currentSelectedFilter ="currentSelectedFilter" :isFetchingPieChartData="isFetchingPieChartData" />
              </div>
            </div>   
          </div>
        </div>
        
      </div> -->
    </div>
    <!-- <div class="row">
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-12">
            <TransactionVolumeStackedbar :filter="appliedFilter" />
          </div>
        </div>
      </div>
    </div> -->
  </Layout>
</template>

<style scoped>
  ::v-deep .table-custom .table.b-table > thead > tr > .table-b-table-default{
    font-size: 12px;
    border-color: #edece9;
    background-color: #edece9;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    color: rgba(0, 0, 0, 0.50);
    border-radius: 0px;

  }
  ::v-deep .table-custom .table > tbody > tr > td{
    background: transparent;
    border-bottom: 1px solid #DCDAD5;
    padding-top: 14px;
    padding-bottom: 14px;
    color: black;
    font-size: 14px;
    padding-left: 20px;
  }
  ::v-deep  .custom-multiselect .multiselect__tags{
    border: 1px solid #DCDAD5  !important;
    height: 44px;
    padding: 13px 16px !important;
    border-radius: 12px;
    color: #000 !important;
    cursor: pointer;
  }
  ::v-deep .custom-multiselect .multiselect__select:before{
    z-index: 9;
    top: 25px;
  }
  ::v-deep .custom-multiselect .multiselect__select{
    min-height: 50px !important;
    width: 30px;
  }
  ::v-deep  .custom-multiselect .multiselect__single{
    font-size: 14px !important;
  }
  ::v-deep  .custom-multiselect .multiselect__content-wrapper{
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
      border-radius: 12px;
      border: none !important;
      padding-top: 10px;
      padding-bottom: 5px;
  }
  ::v-deep  .custom-multiselect .multiselect__element{
      border-bottom: 1px rgba(220, 218, 213, 0.30) solid;
  }
  ::v-deep  .custom-multiselect .multiselect__option--selected{
      background: rgba(220, 218, 213, 0.30);
      border-right: 3px solid #FC6B2D;
  }
  ::v-deep  .custom-multiselect .multiselect__option{
      padding: 13px 16px;
      font-size: 14px;
      font-weight: 500;
  }
  ::v-deep .card {
    border-radius: 12px;
    background-color: #dcdad54d;
    }
  ::v-deep .card-body{
    padding: 25px;
  }
  .loading-box{
    background-color: rgb(0 0 0 / 25%);
    z-index: 1 !important;
}
::v-deep .loading-box .lds-roller div:after{
    background-color: #252B3A;
}
.static_section{
    display: flex;
    justify-content: space-between;
    padding: 25px 25px 0px;
    align-items: center;
}
.static_section .static_filters{
    padding: 7px 12px;
    background: rgba(220, 218, 213, 0.50);
    font-size: 12px;
    border-radius: 8px;
    color: #111727;
    cursor: pointer;
}
.static_section .static_filters.active{
  background: rgba(248, 88, 19, 0.10);
    color: #FC6B2D;
    font-weight: 400;
}
</style>
