<script>
import {getCurrency} from '../../../helpers/utils';
import * as moment from "moment";
/**
 * Widget Component
 */
export default {
  props: ['filter'],
  computed: {
    statData() {
      return this.$store.state.dashboard.txn_stats;
    },
    appliedFilter() {
      return this.filter;
    },
    isFetchingTxnStatsData() {
      return this.$store.state.dashboard.is_fetching_txn_stats_data;
    },
  },
  methods: {
    getCurrencySymbol(currency) {
      return getCurrency(currency)
    },
    valIsFinite(value) {
      return value < 10000000000000000000000000;
    },
    getFormattedPeriod() {
      if(this.statData && this.statData.date_range) {
        const startDate = this.statData.date_range.start;
        const endDate = this.statData.date_range.end;
        if(this.filter == 'hours') {
          return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('HH:mm')}`;
        } else if(this.filter == 'days') {
          return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('HH:mm')}`;
        } else if(this.filter == 'weeks') {
          return `${moment(startDate).format('YYYY/MM/DD')} - ${moment(endDate).format('YYYY/MM/DD')}, Week: ${moment(startDate).week()}`;
        } else if(this.filter == 'months') {
          return `${moment(startDate).format('YYYY/MM')}`;
        } else if(this.filter == 'years') {
          return `${moment(startDate).format('YYYY')}`;
        } else {
          return `${moment(startDate).format('YYYY/MM/DD HH:mm')} - ${moment(endDate).format('YYYY/MM/DD HH:mm')}`;
        }
      }
    },
    getFormattedAmount(val){
        if(val == "net_txns"){
          if(this.statData && this.statData.net_txns_volume){
              return  `${(this.statData.net_txns_volume.value).toLocaleString('en-US', {style : 'currency', currency : this.statData.net_txns_volume.currency === 'ALL' ? 'USD' : this.statData.net_txns_volume.currency})}`
          }
        }
      if(val == "average_txn"){
          if(this.statData && this.statData.average_txns_volume){
              return  `${(this.statData.average_txns_volume.value).toLocaleString('en-US', {style : 'currency', currency : this.statData.average_txns_volume.currency === 'ALL' ? 'USD' : this.statData.average_txns_volume.currency})}`
          }
        }
          
    }
  },
};
</script>

<template>
  <div class="row">
    <!--  Net Transaction Volume -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body" style="position: relative; height: 160px;">
          <div class="media" id="net_txns_volume_widget">
            <div class="media-body overflow-hidden">
              <p class="font-14px" style="color: #000000b3; margin-bottom: 8px; line-height: 18px;">{{ $t('views.dashboard.widget_net_transaction_volume') }} <br> {{ $t('views.dashboard.widget_net_transaction_volume2') }}</p>
              <!-- <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div> -->
              <div id="net_txns_volume_val">
                <h3 class="mb-0 text-truncate" style="font-size: 28px;font-weight: 400; padding: 5px 0px;"  v-if="statData.net_txns_volume && !isFetchingTxnStatsData">
                  {{ (statData.net_txns_volume.value).toLocaleString('en-US', {style : 'currency', currency : statData.net_txns_volume.currency === 'ALL' ? 'USD' : statData.net_txns_volume.currency}) }}
                </h3>
                <h3 v-else class="skeleton-box w-75 mb-0"></h3>
              </div>
              <b-tooltip v-if="statData.net_txns_volume &&  ((statData.net_txns_volume.value).toLocaleString('en-US', {style : 'currency', currency : statData.net_txns_volume.currency === 'ALL' ? 'USD' : statData.net_txns_volume.currency})).length > 11 " target="net_txns_volume_val" triggers="hover">
                <span >{{ getFormattedAmount("net_txns") }}</span>
              </b-tooltip>
            </div>
          </div>
          <!-- <b-tooltip target="net_txns_volume_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `${ $t('views.dashboard.popover.calculated.hourly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `${ $t('views.dashboard.popover.calculated.daily') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `${ $t('views.dashboard.popover.calculated.weekly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `${ $t('views.dashboard.popover.calculated.monthly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `${ $t('views.dashboard.popover.calculated.yearly') }: ${getFormattedPeriod()}` }}</span>
          </b-tooltip> -->
          <div v-if="statData.net_txns_volume && !isFetchingTxnStatsData">
            <div class="text-truncate pt-2" v-if="valIsFinite(statData.net_txns_volume.percent_change)">
              <span v-if="statData.net_txns_volume.percent_change >= 0" style="color: #219653b3; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_up.png" alt="" />
                {{ (statData.net_txns_volume.percent_change).toFixed(2) + '%' }}
              </span>
              <span v-else style="color: #fc6b2d; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_down.png" alt="" />
                {{ (-1 * statData.net_txns_volume.percent_change).toFixed(2) + '%' }}
              </span>
              <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
            <div class="text-truncate pt-2" v-else>
              <span class="badge badge-soft-info font-size-11 ml-2">
                {{ $t('common.na') }}
              </span>
              <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
          </div>
          <div v-else class="skeleton-box w-100 pt-2"></div>
        </div>
      </div>
    </div>

    <!--  Net Transaction Count -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body" style=" position: relative; height: 160px;">
          <div class="media" id="net_txns_count_widget">
            <div class="media-body overflow-hidden">
              <p class="font-14px" style="color: #000000b3; margin-bottom: 8px; line-height: 18px;">{{ $t('views.dashboard.widget_net_transaction_volume3') }} <br> {{ $t('views.dashboard.widget_net_transaction_count') }}</p>
              <!-- <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div> -->
              <h3 class="mb-0" style="font-size: 28px;font-weight: 400; padding: 5px 0px;" v-if="statData.net_txns_count && !isFetchingTxnStatsData">{{ statData.net_txns_count.value }}</h3>
              <h3 v-else class="skeleton-box w-75 mb-0"></h3>
            </div>
          </div>
          <!-- <b-tooltip target="net_txns_count_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `${ $t('views.dashboard.popover.calculated.hourly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `${ $t('views.dashboard.popover.calculated.daily') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `${ $t('views.dashboard.popover.calculated.weekly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `${ $t('views.dashboard.popover.calculated.monthly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `${ $t('views.dashboard.popover.calculated.yearly') }: ${getFormattedPeriod()}` }}</span>
          </b-tooltip> -->
          <div v-if="statData.net_txns_count && !isFetchingTxnStatsData">
            <div class="text-truncate pt-2" v-if="valIsFinite(statData.net_txns_count.percent_change)">
              <span v-if="statData.net_txns_count.percent_change >= 0" style="color: #219653b3; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_up.png" alt="" />
                {{ (statData.net_txns_count.percent_change).toFixed(2) + '%' }}
              </span>
              <span v-else style="color: #fc6b2d; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_down.png" alt="" />
                {{ (-1 * statData.net_txns_count.percent_change).toFixed(2) + '%' }}
              </span>
              <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
            <div class="text-truncate pt-2" v-else>
              <span class="badge badge-soft-info font-size-11 ml-2">
                {{ $t('common.na') }}
              </span>
              <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
          </div>
          <div v-else class="skeleton-box w-100 pt-2"></div>
        </div>
      </div>
    </div>

    <!--  Average Transaction Value -->
    <div class="col-md-3">
      <div class="card">
        <div class="card-body" style="position: relative; height: 160px;">
          <div class="media" id="average_txns_volume_widget">
            <div class="media-body overflow-hidden">
              <p class="font-14px" style="color: #000000b3; margin-bottom: 8px; line-height: 18px;">{{ $t('views.dashboard.widget_average_order_value') }} <br> <br></p>
              <!-- <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div> -->
              <div id="average_txns_volume_val">
                <h3 class="mb-0 text-truncate" style="font-size: 28px;font-weight: 400; padding: 5px 0px;" v-if="statData.average_txns_volume && !isFetchingTxnStatsData">
                    {{ (statData.average_txns_volume.value).toLocaleString('en-US', {style : 'currency', currency : statData.average_txns_volume.currency === 'ALL' ? 'USD' : statData.average_txns_volume.currency}) }}
                </h3>
                <h3 v-else class="skeleton-box w-75 mb-0"></h3>
                <b-tooltip v-if=" statData.average_txns_volume &&  ((statData.average_txns_volume.value).toLocaleString('en-US', {style : 'currency', currency : statData.average_txns_volume.currency === 'ALL' ? 'USD' : statData.average_txns_volume.currency})).length > 11" target="average_txns_volume_val" triggers="hover">
                    <span >{{ getFormattedAmount("average_txn") }}</span>
                </b-tooltip>
              </div>
            </div>
          </div>
          <!-- <b-tooltip target="average_txns_volume_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `${ $t('views.dashboard.popover.calculated.hourly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `${ $t('views.dashboard.popover.calculated.daily') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `${ $t('views.dashboard.popover.calculated.weekly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `${ $t('views.dashboard.popover.calculated.monthly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `${ $t('views.dashboard.popover.calculated.yearly') }: ${getFormattedPeriod()}` }}</span>
          </b-tooltip> -->
          <div v-if="statData.average_txns_volume && !isFetchingTxnStatsData">
            <div class="text-truncate pt-2" v-if="valIsFinite(statData.average_txns_volume.percent_change)">
              <span v-if="statData.average_txns_volume.percent_change >= 0" style="color: #219653b3; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_up.png" alt="" />
                {{(statData.average_txns_volume.percent_change).toFixed(2) + '%'}}
              </span>
              <span v-else style="color: #fc6b2d; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_down.png" alt="" />
                {{ (-1 * statData.average_txns_volume.percent_change).toFixed(2) + '%'}}
              </span>
              <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
            <div class="text-truncate pt-2" v-else>
              <span class="badge badge-soft-info font-size-11 ml-2">
                {{ $t('common.na') }}
              </span>
              <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
          </div>
          <div v-else class="skeleton-box w-100 pt-2"></div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="card">
        <div class="card-body" style="position: relative; height: 160px;">
          <div class="media" id="success_data_widget">
            <div class="media-body overflow-hidden">
              <p class="font-14px" style="color: #000000b3; margin-bottom: 8px; line-height: 18px;">{{ $t('views.dashboard.widget_average_order_value2') }} <br> <br></p>
              <!-- <div style="width: 35px;border: 2px solid #354052;" class="mb-2"></div> -->
              <h3 class="mb-0" style="font-size: 28px;font-weight: 400; padding: 5px 0px;" v-if="statData.success_data && !isFetchingTxnStatsData">{{ statData.success_data.amountPercentage }}%</h3>
              <h3 v-else class="skeleton-box w-75 mb-0"></h3>
            </div>
            <!-- <div class="text-primary">
              <i :class="`ri-stack-line font-size-24`"></i>
            </div> -->
            <!-- <img src="@/assets/images/dashboard_avrg_value.svg" style="position: absolute;right: 10px;" alt="" /> -->
          </div>
          <!-- <b-tooltip target="success_data_widget" triggers="hover">
            <span v-if="filter === 'hours'" class="">{{ `${ $t('views.dashboard.popover.calculated.hourly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'days'" class="">{{ `${ $t('views.dashboard.popover.calculated.daily') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'weeks'" class="">{{ `${ $t('views.dashboard.popover.calculated.weekly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'months'" class="">{{ `${ $t('views.dashboard.popover.calculated.monthly') }: ${getFormattedPeriod()}` }}</span>
            <span v-else-if="filter === 'years'" class="">{{ `${ $t('views.dashboard.popover.calculated.yearly') }: ${getFormattedPeriod()}` }}</span>
          </b-tooltip> -->
          <div v-if="statData.success_data && !isFetchingTxnStatsData">
            <div class="text-truncate pt-2" v-if="valIsFinite(statData.success_data.amountPercentageChange)">
              <span v-if="statData.success_data.amountPercentageChange >= 0" style="color: #219653b3; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_up.png" alt="" />
                {{(statData.success_data.amountPercentageChange).toFixed(2) + '%'}}
              </span>
              <span v-else style="color: #fc6b2d; font-weight: 500;" class="font-size-12">
                <img class="align-middle" style="height: 12px;" src="@/assets/images/dashboard_down.png" alt="" />
                {{ (-1 * statData.success_data.amountPercentageChange).toFixed(2) + '%'}}
              </span>
              <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
            <div class="text-truncate pt-2" v-else>
              <span class="badge badge-soft-info font-size-11 ml-2">
                {{ $t('common.na') }}
              </span>
               <span style="font-size: 12px; color: #00000080;" class="text-muted">{{ $t('views.dashboard.widget_from_previous_period') }}</span>
            </div>
          </div>
          <div v-else class="skeleton-box w-100 pt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
 
<style lang="scss" scoped>
.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1.8s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
